import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { quoteActions } from '../../_actions';
import { compareUtil, numberWithCommas, addCapSpace } from '../../_helpers';
import NumericFormatCustom from '../NumericFormatCustom';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import ReplayIcon from '@mui/icons-material/Replay';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';
import Container from '@mui/material/Container';
import { FormControl } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import dayjs from 'dayjs';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import QuoteInfoModal from './QuoteInfoModal';
import PriceIndicatorBar from '../PriceIndicatorBar';

const headCells = [
    { id: 'Id', numeric: true, label: 'Quote No.' },
    { id: 'QuoteDate', numeric: false, label: 'Quote Date' },
    { id: 'VendorName', numeric: false, label: 'Vendor Name' },
    { id: 'QuoteCurrency', numeric: false, label: 'Quote Curr.' },
    { id: 'TotalPrice', numeric: true, label: 'Total Price' },
    { id: 'ConvertedTotalPrice', numeric: true, label: 'Converted Total Price' },
    { id: 'TransitDays', numeric: true, label: 'Transit Days' },
    { id: 'BestPrice', numeric: true, label: 'Best Price' },
    { id: 'FSC', numeric: true, label: 'FSC' },
    { id: 'DropFee', numeric: true, label: 'Drop Fee' },
    { id: 'DateAvailable', numeric: false, label: 'Date Available' },
    { id: 'EquipmentType', numeric: false, label: 'Equipment Type' },
    { id: 'TrailerType', numeric: false, label: 'Trailer Type' },
    { id: 'LastEdited', numeric: false, label: 'Last Edited' },
    { id: 'Comments', numeric: false, label: 'Comments' },
    { id: 'VendorEmail', numeric: false, label: 'Vendor Email' },
];

const classes = {
    formHeader: {
        fontSize: 20,
        paddingBottom: 1
    },
    formSection: {
        paddingLeft: 2,
        paddingRight: 2,
        paddingBottom: 2,
        marginBottom: 2,
    },
    formInput: {
        width: '100%',
        "& .MuiInputBase-root.Mui-disabled": {
            "& > fieldset": {
                borderColor: 'blue',
            },
            "& .MuiInputBase-input": {
                WebkitTextFillColor: 'black', // Use this to change text color
                color: 'black', // Fallback for other browsers
                backgroundColor: '#F2F5F7',
            }
        },
        "& .MuiFormLabel-root.Mui-disabled": {
            color: 'black', // Change this to the desired label color
        }
    },
    datePickerStyle: {
        marginTop: 2,
    },
    revokeButton: {
        textTransform: 'none',
		fontSize: 15,
		marginTop: 3,
		paddingLeft: 3.5,
		paddingRight: 3.5,
        left: '41%',
    },
    quotesReceivedTable: {
        marginTop: 10
    },
    quotesReceivedTableHeader: {
        fontWeight: 'bold',
        textAlign: 'center',
    },
    awardButton: {
        textTransform: 'none',
		fontSize: 15,
		paddingLeft: 3.5,
		paddingRight: 3.5,
        float: 'right'
    },
    selectInput: {
		width: 180,
        color: 'black',
        backgroundColor: 'white'
	},
	tableInfo: {
		marginBottom: 1,
	},
    tableStyle: {
        whiteSpace: 'nowrap',
        maxHeight: 710
    },
	tableHeaderStyle: {
		fontWeight: 'bold',
	},
    tableRow: {
        cursor: 'pointer',
        "&.Mui-selected": {
            backgroundColor: "#2596be",
            "&:hover": {
                backgroundColor: "#2596be"
              }
        },
    },
    toolTipStyle: {
        color: '#2c88d9',
    }
};

function AwardQuoteTab(){
    let { loadId } = useParams();
    const [showCurrencyIn, setShowCurrencyIn] = useState('CAD');
    const [modifiedQuotes, setModifiedQuotes] = useState([]);
    const [selectedQuote, setSelectedQuote] = useState(0);
    const [lowestTotalPrice, setLowestTotalPrice] = useState(0);
    const [highestTotalPrice, setHighestTotalPrice] = useState(0);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('ConvertedTotalPrice');
	const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openQuoteInfoModal, setOpenQuoteInfoModal] = useState(false);
	const handleOpenQuoteInfoModal = () => setOpenQuoteInfoModal(true);
    const [openNoQuoteSelectedDialog, setOpenNoQuoteSelectedDialog] = useState(false);
    const dispatch = useDispatch();
    const loading = useSelector(state => state.quote.loading);
    const quotes = useSelector(state => state.quote.quotes);
    const winningQuote = useSelector(state => state.quote.winningQuote);
    const exchangeRates = useSelector(state => state.quote.exchangeRates);

    useEffect(() => {  
        dispatch(quoteActions.getWinningQuote(loadId));
        dispatch(quoteActions.getQuotes(loadId));
        dispatch(quoteActions.getExchangeRates());
    }, []);

    useEffect(() => {  
        convertCurrency();
    }, [showCurrencyIn]);

    useEffect(() => {  
        setModifiedQuotes(quotes);
        findLowestTotalPrice(quotes);
        findHighestTotalPrice(quotes);
    }, [quotes]);

    const revokeQuote = () => {
        dispatch(quoteActions.revokeWinningQuote(loadId, winningQuote.Id));
    };

    const awardQuote = () => {
        if(selectedQuote){
            dispatch(quoteActions.awardQuote(loadId, selectedQuote.Id));
        }else{
            setOpenNoQuoteSelectedDialog(true);
        }
    };

    const handleShowCurrencyInChange = (event) => {
        setShowCurrencyIn(event.target.value);
    };

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const onRequestSort = (event, property) => {
        handleRequestSort(event, property);
    };

	const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const openQuoteInfoForm = currentQuote => {
        handleOpenQuoteInfoModal();
        setSelectedQuote({...currentQuote});
	};

    const handleCloseQuoteInfoModal = (event, reason) => {
        if(reason && reason === "backdropClick") {
            return;
        }
        
        setOpenQuoteInfoModal(false);
    };

    const IconWithTooltip = () => (
        <Tooltip arrow title="Click on a row to view all of the quote details" placement="top" sx={classes.toolTipStyle}>
                <InfoIcon />
        </Tooltip>
    );

    const handleCloseNoQuoteSelectedDialog = () => {
        setOpenNoQuoteSelectedDialog(false);
    };

    const toggleSelectedQuote = (quote) => {
        if(quote === selectedQuote){
            setSelectedQuote(0);
        }else{
            setSelectedQuote(quote);
        }
    };

    const findLowestTotalPrice = (arr) => {
        if (!arr.length) return null; // Return null if the array is empty
    
        // Initialize with the first element's ConvertedTotalPrice
        let lowestPrice = arr[0].ConvertedTotalPrice;
    
        // Iterate through the array
        for (let i = 1; i < arr.length; i++) {
            // Update lowestPrice if a lower value is found
            if (arr[i].ConvertedTotalPrice < lowestPrice) {
                lowestPrice = arr[i].ConvertedTotalPrice;
            }
        }
        
        setLowestTotalPrice(lowestPrice);
    };

    const findHighestTotalPrice = (arr) => {
        let highestPrice = -Infinity; // Start with the smallest possible number
    
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].ConvertedTotalPrice > highestPrice) {
                highestPrice = arr[i].ConvertedTotalPrice; // Update highestPrice if the current ConvertedTotalPrice is greater
            }
        }
        
        setHighestTotalPrice(highestPrice);
    };

    const convertCurrency = () => {
        const updatedQuotes = modifiedQuotes.map(quote => {
            // Check if a currency conversion is needed
            if (quote.QuoteCurrency !== showCurrencyIn) {
                let convertedPrice = quote.ConvertedTotalPrice;
    
                // Perform conversion based on the original currency
                if (quote.QuoteCurrency === 'CAD') {
                    convertedPrice = Math.round((convertedPrice * exchangeRates.CADtoUSD) * 100) / 100;
                } else if (quote.QuoteCurrency === 'USD') {
                    convertedPrice = Math.round((convertedPrice * exchangeRates.USDtoCAD) * 100) / 100;
                }
    
                // Return a new quote object with the converted price
                return {
                    ...quote,
                    ConvertedTotalPrice: convertedPrice
                };
            }else{
                let convertedPrice = quote.ConvertedTotalPrice;
    
                // Perform conversion based on the original currency
                if (quote.QuoteCurrency === 'CAD') {
                    convertedPrice = quote.TotalPrice;
                } else if (quote.QuoteCurrency === 'USD') {
                    convertedPrice = quote.TotalPrice;
                }
    
                // Return a new quote object with the converted price
                return {
                    ...quote,
                    ConvertedTotalPrice: convertedPrice
                };
            }
        });
    
        // Update the state with the new array
        setModifiedQuotes(updatedQuotes);

        // Update the highest and lowest prices for the quote comparison graph
        findLowestTotalPrice(updatedQuotes);
        findHighestTotalPrice(updatedQuotes);
    };

    return (
        <>
            {!loading && winningQuote ?
            <>
            <Typography sx={classes.formHeader}>Winning Quote (ID: {winningQuote.Id})</Typography>
            <Paper elevation={3} sx={classes.formSection}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            defaultValue={winningQuote.FreightCost}
                            disabled
                            label="Freight Cost" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                inputComponent: NumericFormatCustom,
                                sx: { backgroundColor: '#F2F5F7' }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            defaultValue={winningQuote.QuoteCurrency}
                            disabled
                            label="Quote Currency" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            defaultValue={winningQuote.VendorName}
                            disabled
                            label="Vendor Name" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            defaultValue={winningQuote.Contact}
                            disabled
                            label="Contact" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            defaultValue={winningQuote.PhoneNumber}
                            disabled
                            size="small"
                            label="Phone" 
                            variant="outlined" 
                            margin="normal" 
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            defaultValue={winningQuote.Email}
                            disabled
                            label="Email" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            defaultValue={Math.round(winningQuote.TodaysExchangeRate * 100) / 100}
                            disabled
                            label="Today's ER" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            defaultValue={Math.round(winningQuote.QuoteDayExchangeRate * 100) / 100}
                            disabled
                            label="Quote Day's ER" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            defaultValue={dayjs(winningQuote.QuoteDate).format('MMM DD, YYYY h:mm A')}
                            disabled
                            label="Quote Date" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            defaultValue={winningQuote.TransitDays}
                            disabled
                            label="Transit Days" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            defaultValue={winningQuote.BestPrice}
                            disabled
                            label="Best Price" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            defaultValue={winningQuote.FSC}
                            disabled
                            label="FSC" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            defaultValue={winningQuote.DropFee}
                            disabled
                            label="Drop Fee" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            defaultValue={dayjs(winningQuote.DateAvailable).format('MMM DD, YYYY')}
                            disabled
                            label="Date Available" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            defaultValue={addCapSpace(winningQuote.EquipmentType)}
                            disabled
                            label="Equipment Type" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            defaultValue={addCapSpace(winningQuote.TrailerType)}
                            disabled
                            label="Trailer Type" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            defaultValue={winningQuote.LastEdited ? dayjs(winningQuote.LastEdited).format('MMM DD, YYYY h:mm A') : 'N/A'}
                            disabled
                            label="Last Edited" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <TextField 
                            defaultValue={winningQuote.Comments ? winningQuote.Comments : ' '}
                            disabled
                            label="Comments"
                            multiline
                            rows={3} 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                </Grid>
                {!loading ?
                <Button
                    variant="contained"
                    sx={classes.revokeButton}
                    startIcon={<ReplayIcon />}
                    onClick={() => revokeQuote()}
                > 
                    Revoke
                </Button>
                : <LinearProgress />}
            </Paper>
            </>
            : null}
            {!loading && !winningQuote && selectedQuote ?
            <>
            <Typography sx={classes.formHeader}>Selected Quote (ID: {selectedQuote.Id})</Typography>
            <Paper elevation={3} sx={classes.formSection}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            value={selectedQuote.TotalPrice}
                            disabled
                            label="Freight Cost" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                inputComponent: NumericFormatCustom,
                                sx: { backgroundColor: '#F2F5F7' }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            value={selectedQuote.QuoteCurrency}
                            disabled
                            label="Quote Currency" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            value={selectedQuote.VendorName}
                            disabled
                            label="Vendor Name" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            value={selectedQuote.Contact}
                            disabled
                            label="Contact" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            value={selectedQuote.PhoneNumber}
                            disabled
                            size="small"
                            label="Phone" 
                            variant="outlined" 
                            margin="normal" 
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            value={selectedQuote.VendorEmail}
                            disabled
                            label="Email" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            value={Math.round(3.4 * 100) / 100}
                            disabled
                            label="Today's ER" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            value={Math.round(3.5 * 100) / 100}
                            disabled
                            label="Quote Day's ER" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            value={dayjs(selectedQuote.QuoteDate).format('MMM DD, YYYY h:mm A')}
                            disabled
                            label="Quote Date" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            value={selectedQuote.TransitDays}
                            disabled
                            label="Transit Days" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            value={selectedQuote.BestPrice}
                            disabled
                            label="Best Price" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            value={selectedQuote.FSC}
                            disabled
                            label="FSC" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            value={selectedQuote.DropFee}
                            disabled
                            label="Drop Fee" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            value={dayjs(selectedQuote.DateAvailable).format('MMM DD, YYYY')}
                            disabled
                            label="Date Available" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            value={addCapSpace(selectedQuote.EquipmentType)}
                            disabled
                            label="Equipment Type" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            value={addCapSpace(selectedQuote.TrailerType)}
                            disabled
                            label="Trailer Type" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={3}>
                        <TextField 
                            value={selectedQuote.LastEdited ? dayjs(selectedQuote.LastEdited).format('MMM DD, YYYY h:mm A') : 'N/A'}
                            disabled
                            label="Last Edited" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <TextField 
                            value={selectedQuote.Comments ? selectedQuote.Comments : ' '}
                            disabled
                            label="Comments"
                            multiline
                            rows={3} 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                </Grid>
            </Paper>
            </>
            : null}
            {!loading && modifiedQuotes && modifiedQuotes.length >= 5 && selectedQuote ?
            <Paper elevation={3}>
                <Container sx={{width: '80%', padding: 5}}>
                    <PriceIndicatorBar 
                        lowerBound={lowestTotalPrice} 
                        upperBound={highestTotalPrice} 
                        userQuote={selectedQuote.ConvertedTotalPrice}
                    />
                </Container>
            </Paper>
            : null}
            {!loading ?
            <Container maxWidth={false} disableGutters sx={classes.quotesReceivedTable}>
                <Grid container spacing={1} sx={{marginBottom: 1}}>
                    <Grid item sm={4}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="shownInCurrencyLabel">Shown in Currency</InputLabel>
                            <Select value={showCurrencyIn} onChange={handleShowCurrencyInChange} labelId="shownInCurrencyLabel" id="shownInCurrency" sx={classes.selectInput} label="Shown in Currency">
                                <MenuItem value="CAD">CAD</MenuItem>
                                <MenuItem value="USD">USD</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                        <Typography variant="h4" sx={classes.quotesReceivedTableHeader}>Quotes Received <span><IconWithTooltip /></span></Typography>
                    </Grid>
                    <Grid item sm={4}>
                        <Button
                            variant="contained"
                            sx={classes.awardButton}
                            startIcon={<EmojiEventsIcon />}
                            onClick={() => awardQuote()}
                        > 
                            Award
                        </Button>
                        <Dialog
                            open={openNoQuoteSelectedDialog}
                            onClose={handleCloseNoQuoteSelectedDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"No quote has been selected"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Click on a row in the table to select a quote
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseNoQuoteSelectedDialog} color="primary" autoFocus>
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </Grid>
                <Paper elevation={3}>
                    <TableContainer sx={classes.tableStyle}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {headCells.map((headCell) => (
										<TableCell
											key={headCell.id}
											align={'left'}
											sortDirection={orderBy === headCell.id ? order : false}
										>
											<TableSortLabel
												active={orderBy === headCell.id}
												direction={orderBy === headCell.id ? order : 'asc'}
												onClick={createSortHandler(headCell.id)}
												sx={classes.tableHeaderStyle}
											>
												{headCell.label}
												{orderBy === headCell.id ? (
													<Box component="span" sx={visuallyHidden}>
														{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
													</Box>
												) : null}
											</TableSortLabel>
										</TableCell>
									))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {exchangeRates && modifiedQuotes && modifiedQuotes.length > 0 ?
								compareUtil.stableSort(modifiedQuotes, compareUtil.getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row) => (
									<TableRow selected={selectedQuote.Id === row.Id} hover sx={classes.tableRow} key={row.Id} onClick={() => toggleSelectedQuote(row)}>
										<TableCell align="left">{row.Id}</TableCell>
										<TableCell align="left">{dayjs(row.QuoteDate).format('MMM DD, YYYY h:mm A')}</TableCell>
										<TableCell align="left">{row.VendorName}</TableCell>
										<TableCell align="left">{row.QuoteCurrency}</TableCell>
										<TableCell align="left">${numberWithCommas(row.TotalPrice)}</TableCell>
                                        <TableCell align="left">${numberWithCommas(row.ConvertedTotalPrice.toFixed(2))} ({showCurrencyIn})</TableCell>
                                        <TableCell align="left">{row.TransitDays}</TableCell>
										<TableCell align="left">${numberWithCommas(row.BestPrice)}</TableCell>
										<TableCell align="left">${numberWithCommas(row.FSC)}</TableCell>
                                        <TableCell align="left">${numberWithCommas(row.DropFee)}</TableCell>
                                        <TableCell align="left">{dayjs(row.DateAvailable).format('MMM DD, YYYY')}</TableCell>
                                        <TableCell align="left">{addCapSpace(row.EquipmentType)}</TableCell>
                                        <TableCell align="left">{addCapSpace(row.TrailerType)}</TableCell>
                                        <TableCell align="left">{row.LastEdited ? dayjs(row.LastEdited).format('MMM DD, YYYY h:mm A') : 'N/A'}</TableCell>
                                        <TableCell align="left">{row.Comments}</TableCell>
                                        <TableCell align="left">{row.VendorEmail}</TableCell>
									</TableRow>
								))
								: null}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={modifiedQuotes.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
                </Paper>
                <QuoteInfoModal openQuoteInfoModal={openQuoteInfoModal} handleCloseQuoteInfoModal={handleCloseQuoteInfoModal} selectedQuote={selectedQuote} />
            </Container>
            : <LinearProgress />}
        </>
    );
}

export default AwardQuoteTab;
import { quoteConstants } from '../_constants';
import { quoteService } from '../_services';
import { alertActions } from './';

export const quoteActions = {
    postLoadForQuoting,
    postLoadForIndividualQuoting,
    getQuotes,
    getWinningQuote,
    awardQuote,
    revokeWinningQuote,
    getQuoteInfoForPdf,
    getExchangeRates
};

function postLoadForQuoting(data) {
    return dispatch => {
        dispatch(request());
        quoteService.postLoadForQuoting(data)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        dispatch(alertActions.success('Load has been posted for quoting!'));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: quoteConstants.POST_LOAD_FOR_QUOTING_REQUEST } }
    function success() { return { type: quoteConstants.POST_LOAD_FOR_QUOTING_SUCCESS } }
    function failure() { return { type: quoteConstants.POST_LOAD_FOR_QUOTING_FAILURE } }
}

function postLoadForIndividualQuoting(data) {
    return dispatch => {
        dispatch(request());
        quoteService.postLoadForIndividualQuoting(data)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        dispatch(alertActions.success('Load has been posted for quoting!'));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: quoteConstants.POST_LOAD_FOR_INDIVIDUAL_QUOTING_REQUEST } }
    function success() { return { type: quoteConstants.POST_LOAD_FOR_INDIVIDUAL_QUOTING_SUCCESS } }
    function failure() { return { type: quoteConstants.POST_LOAD_FOR_INDIVIDUAL_QUOTING_FAILURE } }
}

function getQuotes(loadId) {
    return dispatch => {
        dispatch(request());
        quoteService.getQuotes(loadId)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success(response.data.Result));
                        dispatch(alertActions.success('Found quotes!'));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: quoteConstants.GET_QUOTES_REQUEST } }
    function success(quotes) { return { type: quoteConstants.GET_QUOTES_SUCCESS, quotes } }
    function failure() { return { type: quoteConstants.GET_QUOTES_FAILURE } }
}

function getWinningQuote(loadId) {
    return dispatch => {
        dispatch(request());
        quoteService.getWinningQuote(loadId)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success(response.data.Result));
                        dispatch(alertActions.success('Found winning quote!'));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: quoteConstants.GET_WINNING_QUOTE_REQUEST } }
    function success(winningQuote) { return { type: quoteConstants.GET_WINNING_QUOTE_SUCCESS, winningQuote } }
    function failure() { return { type: quoteConstants.GET_WINNING_QUOTE_FAILURE } }
}

function awardQuote(loadId, quoteId) {
    return dispatch => {
        dispatch(request());
        quoteService.awardQuote(loadId, quoteId)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        dispatch(alertActions.success('Quote has been awarded!'));
                        window.location.reload();
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: quoteConstants.AWARD_QUOTE_REQUEST } }
    function success() { return { type: quoteConstants.AWARD_QUOTE_SUCCESS } }
    function failure() { return { type: quoteConstants.AWARD_QUOTE_FAILURE } }
}

function revokeWinningQuote(loadId, quoteId) {
    return dispatch => {
        dispatch(request());
        quoteService.revokeWinningQuote(loadId, quoteId)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        dispatch(alertActions.success('Winning quote has been revoked!'));
                        window.location.reload();
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: quoteConstants.REVOKE_WINNING_QUOTE_REQUEST } }
    function success() { return { type: quoteConstants.REVOKE_WINNING_QUOTE_SUCCESS } }
    function failure() { return { type: quoteConstants.REVOKE_WINNING_QUOTE_FAILURE } }
}

function getQuoteInfoForPdf(loadId) {
    return dispatch => {
        dispatch(request());
        quoteService.getQuoteInfoForPdf(loadId)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success(response.data.Result));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: quoteConstants.GET_QUOTE_INFO_FOR_PDF_REQUEST } }
    function success(quoteInfoForPdf) { return { type: quoteConstants.GET_QUOTE_INFO_FOR_PDF_SUCCESS, quoteInfoForPdf } }
    function failure() { return { type: quoteConstants.GET_QUOTE_INFO_FOR_PDF_FAILURE } }
}

function getExchangeRates() {
    return dispatch => {
        dispatch(request());
        quoteService.getExchangeRates()
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success(response.data.Result));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: quoteConstants.GET_EXCHANGE_RATES_REQUEST } }
    function success(exchangeRates) { return { type: quoteConstants.GET_EXCHANGE_RATES_SUCCESS, exchangeRates } }
    function failure() { return { type: quoteConstants.GET_EXCHANGE_RATES_FAILURE } }
}